.modal-enter {
    opacity: 0;
}

.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.modal-exit {
    opacity: 1;
}

.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.supermodal {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    &__box {
        background-color: white;
        position: relative;
        padding: 2.5rem;
        border-radius: 1rem;
        max-height: 100vh;
        min-width: 40vw;
        overflow-x: hidden;
        overflow-y: auto;

        button.closeModal {
            all: unset;
            cursor: pointer;
            position: absolute;
            right: 1.25rem;
            top: 0.5rem;
            font-size: 2rem;
        }
    }
}