.petice {
    min-height: 80vh;
    margin: auto;
    display: flex;
    background: linear-gradient(27deg, rgba(0, 70, 113, 1) 20%, rgba(0, 121, 193, 1) 100%);
    align-items: center;
    justify-content: center;
    padding: 6rem 5%;

    &__box {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include breakpoint(tablet) {
            width: 50%;
        }

        @include breakpoint(desktop) {
            width: 40vw;
        }
        @include breakpoint(mobil) {
            width: 90%;
        }


        p,
        .perex h2 {
            text-align: center;
            color: white;
        }

        .perex h2 {
            max-width: 700px;
        }

        .perex p {
            color: $lightBlue;
        }

        button {
            margin-top: 3rem;
        }

    }
}