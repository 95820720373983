.project {
    width: 100%;
    padding: 6rem 5%; 
 
    &__box {
        width: 90%;
        margin: auto;
        display: flex;
        //margin-top: 5rem;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        @include breakpoint(mobil) {
            margin-top: 0;
            width: 90%;
        }
        &__left {
            width: 50%;
            //padding: 3rem;
            //padding-left: 0;
            padding-right: 2rem;

            @include breakpoint(mobil) {
                padding: 1.5rem;
            }

            .icons {
                color: $blue;
                display: flex; 
                gap: 1rem;
                padding: 1.5rem 0;

               img{width: 70px;}
            }
            .perex, p {
                
                max-width: 500px;
            }
            .perex strong{
                font-size: 22px;
            }
        }

        &__right {
            width: 50%;
            //padding: 3rem;
            min-width: 50%;
            min-height: 40rem;
            // align-self: stretch;
            // background-image: url(../assets/img/project_1.png);
            // background-size: cover;
            // background-position: center left;
            // background-repeat: no-repeat;
            // border-radius: 1rem;
            & > div { 
                width: -webkit-fill-available !important;
                height: auto !important;
                aspect-ratio: 16 / 9 !important;
            }
            @include breakpoint(tablet) {
                // background-position: 100%;
                min-height: fit-content !important;
                & > div {
                    width: 90vw !important;
                    height: 50vw !important;
                }
            }
            display: flex;
            justify-content: center;
            align-items: center;

        }

        @include breakpoint(tablet) {
            flex-direction: column;

            &>div {
                width: 100%;
                padding: 2rem;
            }
        }

        @include breakpoint(mobil) {
            width: 90%;

            &>div {
                padding: 1.25rem;
            }
        }
    }

}
