.supertoasts {
    transition: all 0.3s ease-in-out;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    z-index: 99;
}
.supertoast {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-width: 90vw;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    &.info {
        background-color: rgb(147 51 234);
        // @apply bg-purple-600;
    }
    &.success {
        background-color: rgb(101 163 13);
        // @apply bg-blue-600;
    }
    &.error {
        background-color: rgb(220 38 38);
        // @apply bg-red-600;
    }
    &.warn {
        background-color: rgb(217 119 6);
        // @apply bg-amber-600;
    }
    p {width: 100%;}
    button {
        all: unset;
        cursor: pointer;
        font-size: 1.5rem;
    }
}

.toast-enter {
    opacity: 0;
    transform: translate(0, 100%);
}
.toast-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 300ms;
}
.toast-exit {
    opacity: 1; 
    transform: translate(0, 0);
}
.toast-exit-active {
    opacity: 0;
    transform: translate(0, 100%);
    transition: all 300ms;
}