.kurzy {
    width: 100%;
    padding: 6rem 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    .perex,
    p,
    .perex h2 {
        text-align: center;
        color: white;
    }

    @include breakpoint(mobil) {
        padding: 2rem 1rem
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: $lightBlue;
        width: 100%;
        height: 75%;
    }

    &__box {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include breakpoint(mobil) {
            width: 90%;
        }

        p,
        .perex h2 {
            text-align: center;
            color: white;
        }

        .perex h2 {
            max-width: 700px;
        }
 

        button {
            margin-top: 3rem;
        }

    }

    &__container { 
        background-color: white;
        border-radius: 1rem;
        outline: 2px solid $blue;
        display: flex;
        margin-top: 5rem;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        @include breakpoint(mobil) {
            width: 100%;
        }

        p {
            color: black;
            text-align: left;
        }

        &__left {
            min-width: 50%;
            min-height: 40rem;
            align-self: stretch;
            background-image: url(../assets/img/kurzy_1.png);
            background-size: cover;
            background-position: center right;
            background-repeat: no-repeat;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;

            @include breakpoint(tablet) {
                width: 100%;
                height: 53vw;
                border-top-right-radius: 1rem;
                border-bottom-left-radius: 0;
                min-height: 250px;
            }

            img {
                position: absolute;
                left: 0;
                bottom: 0;
                max-width: 75vw;
                &.bg {mix-blend-mode: multiply;}
            }
        }

        &__right {
            width: 50%;
            padding: 5rem;

            &__btns {
                display: flex;
                align-items: center;
                gap: 1rem;
            }

            @include breakpoint(tablet) {
                width: 100%;
            }

            @include breakpoint(mobil) {
                padding: 1.5rem;
            }

            span {
                font-size: 1.2rem;
                padding-bottom: 1rem;
                font-weight: bold;
                color: black;
            }

            p {
                padding-top: 1rem;
                padding-bottom: 3rem;
            }

            button {
                margin-top: 2rem;

                @include breakpoint(mobil) {
                    margin: auto;
                    margin-top: 2rem;
                }
            }
        }
    }
}