@import "mixins";

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scrollbar-width: thin;
    line-height: 1.5;
}

:root {
    font-size: 16px;
    --cc-btn-primary-bg:#0079C1 !important;
    --cc-btn-primary-border-color:#0079C1 !important;
}

body {
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    min-height: 100vh;
}

body:has(main.terminy) {
    display: flex;
    flex-direction: column;
    footer {margin-top: auto;}
    #terminyBtn {display: none;}
}

main {
    overflow-x: clip;
}
#statusUpdate{
    display: none
}
h1,
h2,
h3,
h4 {
    font-family: "Source Sans Pro", sans-serif;
}

h2 {
    font-size: 2rem;
    padding-bottom: 1rem;
}

a {
    text-decoration: none;
}

mark {
    all: unset;
}

.formModal {
    width: 45vw;
    min-width: 300px;
    max-width: 2000px;

    .perex h2 {
        color: black;
        max-width: 300px;
        font-size: 2rem;
        text-wrap: wrap;
        line-height: 1.3;
    }
}

button, a.button {
    all: unset;
    cursor: pointer;
    display: block;
    width: fit-content;
    background-color: $blue;
    color: black;
    padding: 0.75rem 1.75rem;
    text-transform: uppercase;
    border-radius: 0.6rem;
    border: 2px solid $blue;
    @include sourceSans;
    font-weight: 600;
    font-size: 18px;
    @include breakpoint(mobil) {
        font-size: 0.8rem;
    }

    &:hover {
        background-color: transparent;
    } 
    &.disabled { 
        background-color: gray;
        color: white;
        border-color: gray;
    }
    &.blue {
        background-color: $lightBlue;
        color: white;
        border-color: $lightBlue;

        &:hover {
            background-color: transparent;
            color: $lightBlue;
        }
    }

    &.transBlue {
        background: none;
        border-color: $lightBlue;
        color: $lightBlue;

        &:hover {
            background-color: $lightBlue;
            color: white;
        }
    }

    //nevyužito?
    &.transBlack {
        background: none;
        border-color: black;
        color: black;

        &:hover {
            background-color: black;
            color: white;
        }
    }

    &.transWhite {
        background: none;
        border-color: white;
        color: white;

        &:hover {
            background-color: $blue;
            border-color: $blue;
            color: white;
        }
    }
}

.perex {
    padding: 0 0 1rem 0;

    p {
        text-transform: uppercase;
        font-weight: 600;
        color: $lightBlue;
    }

    h2 {
        font-size: 3rem;
        padding: 0.5rem 0 0.5rem 0;
        color: $blue;
        font-style: normal;
        font-weight: 700;
        line-height: 72px;
        /* 100% */
        text-transform: uppercase;

        * {
            line-height: 1;
        }

        @include breakpoint(mobil) {
            font-size: 2rem;
            line-height: 50px;
        }
    }
}

.form {
    background-color: white;
    border-radius: 1rem;
}


.ikonka_zj {
    filter: invert(1);
    transform: scaleX(-100%);
    width: 30px;
    cursor: pointer;
}
.overlay .ikonka_zj, section.kurzy .ikonka_zj {filter: none;}

button.loading {
    margin-left: auto;

    div {
        min-height: 0 !important;
        .spinner {
            height: 25px;
            width: 25px;
            border-color: white;
        }
    }
    &:hover .spinner {border-color: $lightBlue;}

    @include breakpoint(mobil) {
        margin: auto;
        margin-top: 1rem;
    }
}

.superdropdown{
    width: 100%;
}
 
.spinner {
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 6px solid #0079C1;
    animation: spinner-bulqg1 0.8s infinite linear alternate,
         spinner-oaa3wk 1.6s infinite linear;
         margin: auto;
 }
 
 @keyframes spinner-bulqg1 {
    0% {
       clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }
 
    12.5% {
       clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }
 
    25% {
       clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }
 
    50% {
       clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
 
    62.5% {
       clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
 
    75% {
       clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }
 
    100% {
       clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
 }
 
 @keyframes spinner-oaa3wk {
    0% {
       transform: scaleY(1) rotate(0deg);
    }
 
    49.99% {
       transform: scaleY(1) rotate(135deg);
    }
 
    50% {
       transform: scaleY(-1) rotate(0deg);
    }
 
    100% {
       transform: scaleY(-1) rotate(-135deg);
    }
 }
@include breakpoint(mobil) {
    .supermodal{

        padding: 1rem !important;
    }
    .supermodal__box { 
        padding: 1rem !important;
        min-width: 95vw !important;
        max-height: 95vh!important;
    }

    .superform label.half {
        width: 100% !important;
    }
}


@include breakpoint(laptop) {
    .supertoast {
        padding: 1.5rem 3rem !important;
        font-size: larger
    }
}
@import "headfoot";
@import "1intro";
@import "2project";
@import "3kurzy";
@import "4events";
@import "5petice";
@import "6contact";
@import "terminy";