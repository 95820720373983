header {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99;

    .hamburger {
        all: unset;
        cursor: pointer;
        z-index: 9;
        .ham-menu {
            width: 50px;
            height: 50px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .line {
                background-color: black;
                border-radius: 1em;
                height: 2px;
                transition: all 0.3s ease-in-out;
                transform-origin: 1px;
                width: 100%;
            }
        }
    }

    .nav {
        // background: ${props =>props.bg||'transparent'};
        // gap: ${props =>props.gap||'10px'};
        // ${props =>props.moveRight&&'margin-left:auto;'}
        align-items: center;
        display: flex;
    }

    .overlayBg {
        position: fixed;
        z-index: 4;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        transition: all 0.3s ease-in-out;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .overlay {
        position: fixed;
        min-width: fit-content;
        display: flex; flex-direction:column; align-items: center;
        z-index: 5;
        transition: all 0.3s ease-in-out;
        top: 0;
        right: 0;
    }
}