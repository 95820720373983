.contact {
    width: 100%;
    padding: 6rem 5%;

    @include breakpoint(mobil) {
        padding: 2rem 1rem
    }

    &__box {
        width: 80%;
        margin: auto;
        display: flex;
        margin-top: 5rem;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        @include breakpoint(mobil) {
            width: 90%;
            margin-top: 3rem;
        }

        &__left {
            width: 50%;
            padding: 3rem;
            padding-left: 0;
            padding-right: 6rem;

            @include breakpoint(mobil) {
                padding: 1.5rem;
            }

            img {
                max-width: 100%;
            }

            .socials {
                color: $blue;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-top: 2rem;

                a {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    cursor: pointer;
                    color: $blue;
                }
            }
        }

        &__right {
            width: 50%;
            padding: 3rem;
            min-width: 50%;
            min-height: 40rem;
            align-self: stretch;
            background-image: url(../assets/img/project_1.png);
            background-size: cover;
            background-position: center right;
            background-repeat: no-repeat;
            border-radius: 1rem;

            @include breakpoint(tablet) {
                margin-top: 2rem;
                background-position: 0%;
                min-height: 20rem;
            }
        }

        @include breakpoint(tablet) {
            flex-direction: column;

            &>div {
                width: 100%;
                padding: 2rem;
            }

            .contact__box__right {
                min-height: 20rem;
                max-height: 50vh;
                background-position: center center;
            }
        }

        @include breakpoint(mobil) {
            width: 90%;

            &>div {
                padding: 1.25rem;
            }
        }
    }

}

.partners {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 6rem 3rem;
    align-items: center;
    filter: invert(1);

    @include breakpoint(tablet) {
        flex-wrap: wrap;
        align-items: center;
        gap: 10px
    }

    img {
        max-width: 150px;
        object-fit: contain;
        object-position: bottom center;

        @include breakpoint(tablet) {
            margin-top: -30px;
            max-width: 110px;
        }

    }

    a {
        &:nth-child(2) {
           img{ max-width: 210px;
            @include breakpoint(tablet) {
                max-width: 150px;
            }
        }
            
        }
        &:last-child {
            img{ @include breakpoint(tablet) {
                max-width: 160px;
            }
            }
        }
    }
}