header {
    top: 1rem !important;
    padding-right: 1rem;
    z-index: 5;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    width: -webkit-fill-available !important;
    margin: 0 1rem;
    @include breakpoint(tablet){
        padding:.6rem .6rem
    }
    @include breakpoint(mobil){
        padding:.2rem .2rem
    }

    .nav{
        gap: 40px !important;
    }
    .hamburger:not(.open) .line {background-color: white !important;}
    a:not(.button) {
        text-transform: uppercase;
        font-weight: 600;
        color: white !important;
    }

    .overlayBg { 
        width: 100vw!important;
        height: 100vh!important;
        top: -20%!important;
        right: 0; 
    }

    .overlay {
        top: -1rem !important;
        a:not(.button) { 
            color: black !important;
        }
        right: -20px !important; 

    }
    .container {
        display: flex;
        padding: 1rem 1.5rem;
        width: 100%;
        align-items: center;

        .pv0 {
            margin-right: 2rem;

            @include breakpoint(tablet) {
                margin-right: .6rem;
            }
        }

        a {
            height: inherit;
        }

        img {
            height: 100%;
            max-width: 100%;
            @include breakpoint(tablet) {
                max-width: 90px;
            }
            @include breakpoint(mobil) {
                max-width: 80px;
            }

        }
        @include breakpoint(tablet) { 
            padding: 20px 0 20px 10px;
            height: 5rem;
        }
        @include breakpoint(mobil) {
            padding: 10px 0 10px 1rem;
            margin: 0.2rem;
            height: 4rem;
        }
        .button{
            @include breakpoint(tablet) { 
            padding: 0.75rem 0.65rem;}
        }
    }
}

footer {
    background-color: $blue;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .footer {
        padding: 3rem 0;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
 
        @include breakpoint(tablet) {
            max-width: 80vw;
            flex-direction: column;
            gap: 3rem;
            text-align: center;

        }

        @include breakpoint(desktop) {
            max-width: 70vw;
        }



        @include breakpoint(mobil) {
            max-width: 90vw;
            gap: 2rem;
        }

        &__left {
            width: 100%;
            max-width: 400px;

            p {
                font-weight: 200;
                padding-top: 1rem;
                font-size: 0.8rem;
            }

            .container {
                display: flex;
                margin-bottom: 1rem;
                @include breakpoint(mobil) {flex-wrap: wrap; gap: 8px; img {max-width: 50vw;}}
                .vl {
                    border-left: 2px solid white;
                    margin: 0 1rem;
                }

                img {
                    @include breakpoint(mobil) {
                        max-width: 40%;
                    }

                }
            }
        }

        &__right {
            display: flex;
            gap: 6rem;
            width: 40%;

            button {
                all: unset;
            }

            @include breakpoint(mobil) {
                gap: 2rem;
                width: 100%;
                justify-content: center;
            }

            a,
            span {
                display: block;
                text-transform: uppercase;
                cursor: pointer;
                color: white !important;
                padding: 0.25rem 0;
            }

            a,
            span {
                width: fit-content;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: white;
                    bottom: 5%;
                    left: 0;
                    pointer-events: none;
                    transform-origin: 100% 50%;
                    transform: scale3d(0, 1, 1);
                    transition: transform 0.3s;
                }

                &:hover {
                    color: black;

                    &:before {
                        transform-origin: 0% 50%;
                        transform: scale3d(1, 1, 1);
                    }
                }
            }
        }
    }

    .copyright {
        background-color: black;
        width: 100%;
        padding: 0.5rem 5%;
        text-align: center;
        font-size: 0.7rem;
    }
}