.superform {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    sup { vertical-align: baseline; top: -0.25rem; }
    label {
        width: 100%;
        &.half {width: calc(50% - 0.5rem);}

        p:not(.err) {
            width: 100%;
            padding: 0.5rem 0;
            color: black;
        }
        p.err {color: red; padding: 0.25rem 0;font-size: 0.75rem;}
        input, textarea {
            all: unset;
            padding: 0.5rem;
            padding-left: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            transition: all 0.3s ease-in-out;
            color: black !important;
            cursor: auto;
            width: 100%;
            max-width: 100%;
            &:focus {
                border-color: black;
            }
            &.err {
                border-color: red;
            }
        }
    }
}

.checkbox {
    width: 100%;
    &__line {
        display: flex;
        gap: 1rem;
        align-items: center;
        input {
            all: unset;
            cursor: pointer;
            display: block;
            width: 1rem;
            height: 1rem;
            border: 1px solid black;
            transition: all 0.3s ease-in-out;
            &:checked {
                background: black;
            }
        }
        a {text-decoration: underline !important; text-underline-offset: 3px; &:visited {color: inherit;}}
    }
    &.err {
        color: red;
        input {border-color: red;}
    }
}