.intro {
    min-height: 100vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: linear-gradient(
        27deg,
        rgba(0, 70, 113, 1) 20%,
        rgba(0, 121, 193, 1) 100%
    );
    position: relative;

    .swiper {
        height: 100vh;
        width: 100vw;
    }

    .image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        height: 100%;
        width: 100%;
        @include breakpoint(tablet) {
            background-position: 70%;
        }
        @include breakpoint(mobil) {
            background-position: 50%;
        }

        &.i1 {
            background-image: url(../assets/img/PV0-Hendikepovani-hero-banner-3840x2160px-v3.png);
        }
        &.i2 {
            background-image: url(../assets/img/PV0-Hendikepovani-hero-banner-3840x2160px-v3-neslysici.png);
        }
    }

    &__title {
        width: 80vw;
        padding-top: 10rem;
        position: absolute;
        z-index: 2;

        h1 {
            font-size: 5rem;
            color: white;

            span {
                font-weight: 800;
                background-color: $lightBlue;
                border-radius: 1rem;
                padding: 0 1rem;

                &:nth-child(1) {
                    font-weight: 400;
                    padding: 0.75rem 1rem 0.4rem;
                }
                &:last-child {
                    &::after {
                        content: "";
                        background-image: url("../assets/svg/zdarma.svg");
                        height: 80px;
                        width: 200px;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-size: contain;
                        bottom: -3.8rem;
                        right: 0rem;
                        @include breakpoint(mobil) {
                            width: 130px;
                            bottom: -4.2rem;
                        }
                    }
                }
            }

            @include breakpoint(tablet) {
                margin-bottom: 5rem;
                text-align: center;
                font-size: xxx-large;
            }

            @include breakpoint(mobil) {
                font-size: 1.8rem;
                font-size: xx-large;
            }
        }

        button {
            margin-top: 7rem;
            background-color: white;
            border-color: white;
            color: black;

            &:hover {
                background: transparent;
                border-color: white;
                color: white;
            }
        }
    }

    @include breakpoint(tablet) {
        flex-direction: column-reverse;

        button {
            margin: auto;
        }

        img {
            max-height: 50vh;
        }
    }

    @include breakpoint(mobil) {
        padding-top: 5vh;
        width: 100vw;
    }
}
