@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');


$blue: #0079C1;
$lightBlue: #15D1EA;

$lavender: #CA7FFE; 
$lightgray: #F6F5F1;


@mixin sourceSans {
    font-family: "Source Sans Pro", sans-serif;
}


@mixin breakpoint($width) {
    @if $width == mobil {
        @media (max-width: 414px) { @content; }
    } 
    @else if $width == tablet {
        @media (max-width: 1000px) { @content; }
    }
    @else if $width == laptop {
        @media (min-width: 1000px) { @content; }
    }
    @else if $width == desktop {
        @media (min-width: 1800px) { @content; }
    }
    @else if $width == megadesktop {
        @media (min-width: 2560px) { @content; }
    } 
}