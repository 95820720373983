.events {
    width: 100%;
    padding: 6rem 5%;
    margin-bottom: 6rem;
    display: flex;
    align-items: center;
    justify-content: center; 
    @include breakpoint(mobil) {
        margin-bottom: 0;
    }

    &__box { 
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
  
        @include breakpoint(mobil) {
            width: 90%;
        }
        p,
        .perex h2 {
            text-align: center;
            color: white;
        }

        .perex h2 {
            max-width: 700px;
        }


        button {
            margin-top: 3rem;
        }
    }

    &__container {  
        display: flex; 
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: space-around;
        max-width: 100%;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        @include breakpoint(mobil) {
            width: 100%;
            margin-top: 2rem;
        }
    }
}

.event {
    background-color: $blue;
    border-radius: 1.7rem;
    padding: 1.3rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include breakpoint(mobil) {
        width: 100%;
    }
    max-width: 100%;

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
         width: 450px;
         @include breakpoint(mobil) {
            width: 100%;
        }
         max-width: 100%;

        h2 {
            font-size: 2rem; 
            text-wrap: wrap;
            padding-top: 1rem;
        }

        span {
            font-size: 1.2rem;
            text-transform: uppercase;
            text-wrap: wrap;
        }
        p {
            text-align: left;
            background-color: #004068;
            width: fit-content;
            padding: 0.25rem 0.75rem;
            border-radius: 1rem;
            @include breakpoint(mobil) {padding: 0.5rem 1.25rem;}
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 1rem;

        img {
            max-width: 50px;
        }
    }

    button {
        margin: 0;
    }
}

.gallery {
    padding-bottom: 5rem;
    width: 90%;
    margin: auto;
    .swiper {
        border-radius: 2rem;
        .swiper-button-prev, .swiper-button-next {
            width: 60px;
            height: 60px;
            color: $lightBlue;
            font-size: 35px;
        }
        .swiper-slide {
            height: auto !important;
            min-height: 40vh !important;
            img {
                min-height: 40vh;
                height: 100%;
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
}