.terminy {
    &__header {
        background-color: $blue;
        color: white;
        padding: 200px 5% 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            width: 40%;
        }
        @include breakpoint(mobil) {
            flex-direction: column;
            padding-top: 100px;
            p {width: 100%;text-align: center;}
        }
    }
    &__box {
        width: 100%;
        max-width: 100%;
        padding: 6rem 5%;
        &__filter {
            width: 100%;
            padding-bottom: 2rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .divider {
                width: 1rem;
                border-right: 1px solid black;
                margin-right: 1rem;
            }
            .filtr {
                padding: 0 0.5rem 0.5rem;
                width: calc(49% - 1rem);
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                gap: 1rem;
            }
            .sliders {
                margin-left: auto;
                display: flex;
                font-size: 2rem;
                justify-content: center;
                align-items: center;
            }
            .overlay {
                position: fixed;
                z-index: 99;
                top: 0;
                width: 100vw;
                height: 100vh;
                background: $blue;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2rem;
                padding: 1rem 10%;
                button {
                    font-size: initial;
                    margin: 0;
                }
                .close {
                    position: absolute;
                    top: 1.5rem;
                    right: 1.5rem;
                    font-size: 2rem;
                }
            }
            a {
                color: black;
                text-transform: uppercase;
                text-decoration: none;
                padding: 0 .5rem .5rem;
                cursor: pointer;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: .5px; 
                    background-color: $blue;
                    box-shadow: none;
                    bottom: 5%;
                    left: 0;
                    pointer-events: none;
                    transform-origin: 100% 50%;
                    transform: scale3d(0, 1, 1);
                    transition: transform 0.3s;
                    border-bottom: 2px solid transparent;
                }

                &:hover {
                    &:before {
                        transform-origin: 0% 50%;
                        transform: scale3d(1, 1, 1);
                    }
                }

                &.active {
                    &:before {
                        transform-origin: 0% 50%;
                        transform: scale3d(1, 1, 1);
                    }
                }
            } 
        } 
        &__content {
            width: 100%;
            padding: 0 10%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 1rem;
            @include breakpoint(mobil) {
                width: 90%;
                align-items: center;
                padding: 0;
                margin: auto;
            }
            @include breakpoint(tablet) {padding: 0 5%;}
        }
    }
}